@import "custom.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Matter Light';
    src: url('./assets/fonts/matter-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/matter-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter Regular';
    src: url('./assets/fonts/matter-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/matter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nekst Medium';
    src: url('./assets/fonts/nekst_medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/nekst_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
