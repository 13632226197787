@import 'npm:aos/dist/aos.css';

.link {
  @apply inline-block relative;
}

.link::after {
  content: '';
  @apply absolute bottom-0 left-0 h-2px transition-all duration-200 w-0;
}

.tiny-underline::after {
  @apply h-px;
}

.link:hover::after {
  @apply w-full;
}

.link-reverse::after {
  @apply w-full;
}

.link-reverse:hover::after {
  @apply w-0;
}

.link-primary::after {
  @apply bg-primary;
}

.link-secondary::after {
  @apply bg-secondary;
}

.link-secondary-toxic::after {
  @apply bg-secondaryToxic;
}

.link-white {
  color: white;
}

.link-white::after {
  @apply bg-white;
}

.tblock-secondary {
  @apply box-border bg-secondary text-primary border-2 border-secondary;
}

.tblock-primary {
  @apply box-border cursor-pointer bg-primary text-secondary hover:bg-secondary hover:text-primary border-2 border-secondary;
}

.arrow-47 svg {
  width: 47px !important;
  height: 47px !important;
}
.block-row {
  margin-top: -2px;
}
.x-ml-2 {
  margin-left: -2px;
}

.logo-svg {
  svg {
    @apply w-[113px] h-[25px] lg:w-[67px] lg:h-[15px];
  }
}

.logo-svg-large {
  svg {
    @apply w-[113px] h-[25px];
  }
}

.burger-menu-line {
  @apply w-[34px] h-[2px] bg-white block transition-all;
}

.burger-menu-line:nth-child(2) {
  @apply mt-1 relative;
}

.burger-menu-active {
  @apply overflow-hidden;

  .burger-menu-line:nth-child(1) {
    @apply rotate-45;
  }

  .burger-menu-line:nth-child(2) {
    @apply -rotate-45 mt-0 bottom-[2px];
  }

  .burger-menu-body {
    @apply lg:visible lg:opacity-100 h-full;

    .left {
      @apply w-[105px];
    }

    .right {
      @apply h-full;
    }
  }
}

.burger-menu-body {
  @apply invisible opacity-0 flex fixed w-full z-20;

  .left {
    @apply w-0 transition-all;
  }

  .right {
    @apply h-0 transition-all;
  }
}